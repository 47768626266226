import { Controller, useForm } from "react-hook-form";
import InputField from "../InputField";
import { Form } from "react-bootstrap";
import {
  MdOutlineRadioButtonChecked,
  MdOutlineRadioButtonUnchecked,
} from "react-icons/md";
import { FaRegUserCircle } from "react-icons/fa";
import { NavigateBtns } from "../NavigateBtns";
import { ListPicker } from "../ListPicker";
import { usePickerhook } from "../../../hook/usePickerhook";
import { countries } from "../../../constants/countries";
import { emailReg } from "../../../utils/base";
import { toast } from "react-toastify";

export default ({ setpostdata, nextStep, previousStep, postdata }) => {
  const {
    control,
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange", // Ensures validation runs on each change
  });

  const countrypicked = usePickerhook();
  const statepickerhook = usePickerhook();

  const Submit = (data) => {
    
setpostdata((prev)=>
{

  return({...prev, ...{
    ...data,
    country: countrypicked.pickedRole.name,
    state: statepickerhook.pickedRole.name,
  }})
})

  };


  return (
    <div>
      <form onSubmit={handleSubmit(Submit)} className="flex flex-col gap-4">
        <div className="grid grid-cols-1 sm:grid-cols-1 gap-4  md:grid-cols-2  ">
          <InputField
            name="firstname"
            title="First name"
            placeholder="First name"
            control={control}
            rules={{
              required: "is required",
            }}
          />
          <InputField
            name="lastname"
            title="Last name"
            placeholder="Last name"
            control={control}
            rules={{
              required: "is required",
            }}
          />

        
        </div>

        <div className="flex items-center ">   <InputField
            name="email"
            title="Email"
            placeholder="Email"
            control={control}
            rules={{
              required: "is required",
              pattern: {
                value: emailReg,
                message: "Invalid",
              }
            }}
          />
          <h2 className="text-lg">Gender</h2>

          <Controller
            name="gender"
            control={control}
            rules={{ required: "Gender is required" }}
            render={({ field }) => (
              <>
                <Form.Check
                  className="m-2 md:text-[20px] font-thin text-[#293586]"
                  type="radio"
                  id="genderfemale"
                  label="Female"
                  value="Female"
                  checked={field.value === "Female"}
                  onChange={(e) => field.onChange(e.target.value)}
                />
                <Form.Check
                  className="m-2 md:text-[20px] font-thin text-[#293586]"
                  type="radio"
                  id="gendermale"
                  label="Male"
                  value="Male"
                  checked={field.value === "Male"}
                  onChange={(e) => field.onChange(e.target.value)}
                />
              </>
            )}
          />

{errors.gender && <p className="text-red-500 text-xs">{errors.gender.message}</p>}

        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 ">
          <InputField
            name="phone"
            title="Phone number"
            placeholder="Phone number"
             control={control}
            rules={{
              required: "is required",

              pattern: {
                value: /^[0-9]+$/, // Ensures only numbers are allowed
                message: "Only numbers are allowed",
              },
            }}
          />

          <div className="flex justify-between flex-col md:flex-row items-center gap-3 w-full ">
            <ListPicker
              maxwidth
              defaultvalue={{name:countries.filter((kk)=>kk.code3=="NGA")[0].name,code:countries.filter((kk)=>kk.code3=="NGA")[0].code2}}
              hook={countrypicked}
              title="Country"
              list={countries?.map((item) => {
                return { name: item.name, code: item.code2 };
              })}
            />
            <ListPicker
              maxwidth
              hook={statepickerhook}
              
              title="State"
              list={
                countries?.filter(
                  (km) => km.name == countrypicked?.pickedRole?.name
                )[0]?.states
              }
            />
          </div>
        </div>

        <h2 className="text-lg capitalize">Purpose of registering</h2>
        <div className="flex w-full flex-col md:flex-row gap-4 ">
          <StepPicker
            icon={
              <FaRegUserCircle
                size={30}
                className="inline text-white p-1  bg-blue-800 rounded-md "
              />
            }
            action={() =>
              setpostdata((prev) => {
                return { ...prev, purpose: "Participant" };
              })
            }
            selected={postdata.purpose == "Participant"}
            title={"Attend as a participant"}
          />
          <StepPicker
            icon={
              <FaRegUserCircle
                size={30}
                className="inline text-white bg-blue-800 rounded-md p-1 "
              />
            }
            action={() =>
              setpostdata((prev) => {
                return { ...prev, purpose: "Competitior" };
              })
            }
            selected={postdata.purpose == "Competitior"}
            title={"Register as a competitor"}
          />
        </div>

        <NavigateBtns
          shownext={true}
           actionPrev={() => {
            previousStep();
          }}
          nextCondition={true
          }
          actionNext={() => {
if(!statepickerhook.pickedRole ){
toast.error("State is required")
  return
}


if(!postdata.purpose){
  toast.error("Select purpose")

return
}
            if(isValid ){
              nextStep();
            }
           
          }}
        />
      </form>
    </div>
  );
};

export const StepPicker = ({ selected, title, action, icon }) => {
  return (
    <div
      onClick={() => {
        action();
      }}
      className={`rounded-xl border text-lg  flex px-6 py-3 gap-2  max-w-[300px]   ${
        selected ? "bg-faintBlue  !border-mainPink " : " border-gray-300"
      }`}
    >
      <p className="text-xs leading-6 md:text-lg cursor-pointer flex justify-between gap-2 text-customBlue font-semibold">
        <span>{icon}</span> <span >{title}</span>
        {selected ? (
          <MdOutlineRadioButtonChecked className="inline text-mainPink" />
        ) : (
          <MdOutlineRadioButtonUnchecked className="inline text-mainPink" />
        )}
      </p>
    </div>
  );
};
