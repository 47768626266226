import { MdCancel } from "react-icons/md";
import { popimg } from "../../constants/imports";
import { CTAButtons } from "../Events/Carousel";
import { useScroll } from "framer-motion";
import { useState } from "react";
import CountdownTimer from "../Events/CountdownTimer";

export default () => {
  const [show, setshow] = useState(true);

  return (
    <>
      {" "}
      {show ? (
        <div className=" fixed top-0 w-screen z-[100] h-screen bg-black/30 backdrop-blur-sm flex justify-center items-center">
          <div className="flex flex-col gap-2">
            <MdCancel
              onClick={() => {
                setshow(false);
              }}
              size={25}
              className=" text-white inline place-self-end justify-self-end"
            />
                

            <div className="popin bg-white rounded-xl relative w-[95vw] h-[300px] md:w-[40vw] md:h-[400px] flex items-center ">
             
              
              <div className="flex  items-center">
                <div className="w-[55%] h-full flex flex-col justify-center  p-2 ml-2 ">
                  <p className="md:text-[28px] font-light  text-customBlue">
                  RemotewebNET Dynamics Ltd. Mentorship Programme
                  </p>
                  <h2 className="md:text-[40px] ">July 2025</h2>
                  <div className="">

                  <CTAButtons dark />
                    </div>
                </div>
                <img
                  className="absolute top-0 right-0 rounded-tr-xl rounded-br-xl h-full md:h-[400px] "
                  src={popimg}
                />
              </div>
            </div>

            <div className="flex justify-center items-center bg-white/70 rounded-xl scale-75">
            <CountdownTimer eventDate={new Date("2025-07-05T12:00:00")}/></div>
          </div>
        </div>
      ) : (
        ""
      )}{" "}
    </>
  );
};
